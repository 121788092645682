<template>
  <div>

    <ibtabs />
    <b-overlay
      :show="show"
      spinner-variant="primary"
      spinner-type="grow"
      spinner-small
      rounded="sm"
    >
      <b-row>
        <b-col
          v-if="!registerCards || registerCards.status === 'reject'"

          cols="12"
        >
          <b-card :title="$t('register')">
            <ValidationObserver v-slot="{ invalid }">
              <b-form>
                <b-row>
                  <!-- <b-col
                    cols="12"
                    sm="6"
                  >

                    <b-form-group
                      id="average-personal-capital"
                      :label="$t('average-personal-capital')"
                      label-for="average-personal-capital"
                    >
                      <validation-provider
                        v-slot="{ errors }"
                        :name="$t('average-personal-capital')"
                        vid="average-personal-capital"
                        rules="required"
                      >
                        <v-select
                          id="account-number"
                          v-model="form.personal_capital_avg"
                          :options="items.personal_capital_avg"
                          :clearable="false"
                          label="value"
                          :disabled="(registerCards&&registerCards.status === 'active')"
                        />

                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col> -->
                  <b-col
                    cols="12"
                    sm="6"
                  >
                    <b-form-group
                      id="Number_customers_to_introduce"
                      :label="$t('Number_customers_to_introduce')"
                      label-for="Number_customers_to_introduce"
                    >
                      <validation-provider
                        v-slot="{ errors }"
                        :name="$t('Number_customers_to_introduce')"
                        rules="required"
                      >
                        <v-select
                          v-model="form.total_customer"
                          :options="items.total_customer"
                          :clearable="false"
                          label="value"
                          :disabled="(registerCards&&registerCards.status === 'active')"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col
                    cols="12"
                    sm="6"
                  >
                    <b-form-group
                      :label="$t('average-customer-capital')"
                      label-for="average-customer-capital"
                    >
                      <validation-provider
                        v-slot="{ errors }"
                        :name="$t('average-customer-capital')"
                        rules="required"
                      >
                        <v-select
                          v-model="form.customers_capital_avg"
                          :options="items.customers_capital_avg"
                          :clearable="false"
                          label="value"
                          :disabled="(registerCards&&registerCards.status === 'active')"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>

                  <b-col
                    cols="12"
                    sm="6"
                  >
                    <b-form-group
                      id="customer-activity-market"
                      :label="$t('customer-activity-market')"
                      label-for="customer-activity-market"
                    >
                      <validation-provider
                        v-slot="{ errors }"
                        :name="$t('customer-activity-market')"
                        rules="required"
                      >
                        <v-select
                          v-model="form.market"
                          :options="items.market"
                          :clearable="false"
                          label="value"
                          :disabled="(registerCards&&registerCards.status === 'active')"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col
                    cols="12"
                    sm="6"
                  >
                    <b-form-group
                      id="account-id"
                      :label="$t('account-id-rebate')"
                      label-for="account-id"
                    >
                      <validation-provider
                        v-slot="{ errors }"
                        :name="$t('account-id-rebate')"
                        rules="required"
                      >
                        <v-select
                          v-model="form.account"
                          :options="computedAccount"
                          :clearable="false"
                          label="accountPlatform"
                          :disabled="(registerCards&&registerCards.status === 'active')"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <!-- <b-col
                    cols="12"
                    sm="3"
                  >
                    <validation-provider
                      v-slot="{ errors }"
                      :name="$t('start_hour')"
                      rules="required"
                    >
                      <b-form-group
                        id="start_hour"
                        :label="$t('start_hour')"
                        label-for="start_hour"
                      >
                        <date-picker
                          id="start_hour"
                          v-model="form.startHour"
                          color="green"
                          :locale="$i18n.locale"
                          :max="form.endHour"
                          type="time"
                          :disabled="(registerCards&&registerCards.status === 'active')"
                        />
                        <span class="text-danger">{{ errors[0] }}</span>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col
                    cols="12"
                    sm="3"
                  >
                    <validation-provider
                      v-slot="{ errors }"
                      :name="$t('end_hour')"
                      rules="required"
                    >
                      <b-form-group
                        id="end_hour"
                        :label="$t('end_hour')"
                        label-for="end_hour"
                      >
                        <date-picker
                          id="end_hour"
                          v-model="form.endHour"
                          color="red"
                          :locale="$i18n.locale"
                          :min="form.startHour"
                          type="time"
                          :disabled="(registerCards&&registerCards.status === 'active')"
                        />
                        <span class="text-danger">{{ errors[0] }}</span>
                      </b-form-group>
                    </validation-provider>
                  </b-col> -->

                </b-row>
                <b-row>

                  <b-col sm="6">
                    <label for="description">{{ $t('description') }}</label>
                    <b-form-textarea
                      id="textarea-large"
                      v-model="form.description"
                      size="lg"
                      :placeholder="$t('description')"
                      :disabled="(registerCards&&registerCards.status === 'active')"
                    />
                  </b-col>
                </b-row>

                <b-row>
                  <b-col
                    sm="12"
                    class="pt-2"
                  >
                    <div
                      class="p-2"
                      dir="ltr"
                      style="border:1px solid #ccc;max-height:200px;overflow-y:auto;"
                      v-html="termsText"
                    />
                  </b-col>
                </b-row>
                <b-row>
                  <b-col class="my-1">
                    <b-form-group
                      id="checkbox"
                      label-for="checkbox"
                    >
                      <b-form-checkbox
                        id="checkbox-1"
                        v-model="form.terms"
                        required
                        name="checkbox-1"
                        value="accepted"
                        unchecked-value="not_accepted"
                        :checked="false"
                      >
                        <span
                          style=" cursor:pointer"
                          @click.prevent="termsModal = true"
                        >
                          {{ $t('terms_and_conditions') }}
                        </span>
                      </b-form-checkbox>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col cols="auto">
                    <b-button
                      variant="primary"
                      class="flex-end"
                      :disabled="invalid || form.terms !== 'accepted'|| (registerCards&&registerCards.status === 'active')"
                      @click="save"
                    >
                      <span v-if="!isLoading">
                        {{ $t("register") }}</span>
                      <b-spinner
                        v-else
                        small
                        variant="light"
                        type="grow"
                      />
                    </b-button>
                  </b-col>
                </b-row>
              </b-form>
            </ValidationObserver>
          </b-card>
        </b-col>

        <b-col>
          <b-row>
            <b-col
              v-if="registerCards"
              md="6"
              sm="12"
              xl="3"
            >
              <b-alert
                :variant="registerCards.status === 'pending'? 'warning' : (registerCards&&registerCards.status === 'active') ? 'success' : 'danger' "
                show

                class="px-1"
              >
                <h4 class="alert-heading">
                  {{ $t(registerCards.status) }}

                </h4>
                <div class="alert-body">
                  <div style="margin:5px 0">
                    <span>{{ $t('Number_customers_to_introduce') }}</span>
                    <span> : </span>
                    <span>{{ registerCards.total_customer }}</span>
                  </div>
                  <!-- <div style="margin:5px 0">
                    <span>{{ $t('average-personal-capital') }}</span>
                    <span> : </span>
                    <span>{{ registerCards.personal_capital_avg }}</span>
                  </div> -->
                  <div style="margin:5px 0">
                    <span>{{ $t('average-customer-capital') }}</span>
                    <span> : </span>
                    <span>{{ registerCards.customers_capital_avg }}</span>
                  </div>
                  <!-- <div style="margin:5px 0">
                    <span>{{ $t('working_hours') }}</span>
                    <span> : </span>
                    <span>{{ registerCards.working_hour_from + ' - ' +registerCards.working_hour_to }}</span>
                  </div> -->

                </div>
              </b-alert>
            </b-col>
          </b-row>
        </b-col>

        <!-- modal -->
        <b-modal
          v-model="termsModal"
          :title="$t('terms_and_conditions')"
          size="lg"
          ok-only
        >
          <div
            dir="ltr"
            style="text-align: left;"
            v-html="termsText"
          />
          <template #modal-footer>
            <button
              class="btn btn-success btn-sm m-1"
              @click="()=>{
                termsModal =false
                form.terms = 'accepted'
              }"
            >
              {{ $t('ok') }}
            </button>
          </template>
        </b-modal>
      </b-row>
    </b-overlay>
  </div>

</template>

<script>
import {
  BCard, BRow, BCol, BForm, BFormGroup, BButton, BSpinner, BFormTextarea, BOverlay,
  BModal,
  BFormCheckbox,
  BAlert,
} from 'bootstrap-vue'
import { required } from '@validations'
import DatePicker from 'vue-persian-datetime-picker'
import vSelect from 'vue-select'
import PannelIbApis from '@/modules/IB/services/apis'
import ibtabs from './components/ibTabs.vue'
import DescriptionApis from '@/services/apis/description'

const description = new DescriptionApis()

const ib = new PannelIbApis()
export default {
  components: {
    BCard,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BButton,
    BSpinner,
    BFormCheckbox,
    BModal,
    BAlert,
    DatePicker,
    BFormTextarea,
    vSelect,
    ibtabs,
    BOverlay,
  },

  data() {
    return {
      termsModal: false,
      show: false,
      isLoading: false,
      form: {
        account: '',
        total_customer: '',
        customers_capital_avg: '',
        market: '',
        description: '',
        terms: 'accepted',

      },
      items: {
        personal_capital_avg: [],
        total_customer: [],
        customers_capital_avg: [],
        market: [],
        account: [],
      },
      required,
      registerCards: '',
      termsText: '',
    }
  },
  computed: {
    computedAccount() {
      return this.items.account.map(item => ({ ...item, accountPlatform: `${item.account} (${item.platform})` }))
    },
  },

  watch: {
    '$store.state.Messaging.event': {
      async handler(newVal) {
        if (newVal === 'ib_member') {
          await this.getCardData()
          setTimeout(() => {
            this.$router.push({ name: 'ib-dashbord' })
          }, 3000)
          this.$store.dispatch('Messaging/setEvent', '')
        }
      },
      immediate: true,
    },
  },

  async mounted() {
    await this.getFieldData()
    await this.getCardData()
    await description.get({ name: 'agreement-ib' }).then(res => {
      this.termsText = res.data.results.value
    })
    this.show = false
  },
  methods: {
    async getFieldData() {
      this.show = true
      await ib.getRegisterFieldData().then(res => {
        this.items = res.data.results
      })
    },
    async getCardData() {
      await ib.getRegisterData().then(res => {
        // eslint-disable-next-line prefer-destructuring
        this.registerCards = res.data.results
      })
    },
    async save() {
      this.isLoading = true
      const data = {
        account_id: this.form.account.id,
        total_customer_id: this.form.total_customer.id,
        customers_capital_avg_id: this.form.customers_capital_avg.id,
        market_id: this.form.market.id,
        comment: this.form.description,
      }
      await ib.register(data).then(res => {
        this.$swal({
          title: res.data.message,
          icon: 'success',
          timer: 2500,
          showConfirmButton: false,
        })
        this.getCardData()
        this.isLoading = false
      }).catch(err => {
        console.log(err)
        this.isLoading = false
      })
    },
  },
}
</script>

<style>
  .terms_item{
    margin: 6px 0;
  }

  .modal-header .close {
  display:none;
}
</style>
